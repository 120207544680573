<template>
	<div class="Invite">
		<BackButton ref="back" />
		<h1 ref="title">
			Invite a friend
		</h1>
	</div>
</template>

<script>
import { gsap, Quad } from 'gsap/all'

export default {
	name: 'Invite',
	methods: {
		transitionIn () {
			gsap.from(this.$refs.title, { opacity: 0, y: 30, ease: Quad.easeOut })
		},
		transitionOut (cb) {
			this.$refs.back.hide()
			gsap.to(this.$refs.title, { opacity: 0, y: -30, ease: Quad.easeOut, onComplete: cb })
		}
	},
	beforeRouteEnter (to, from, next) {
		next(vm => {
			vm.transitionIn()
		})
	},
	beforeRouteLeave (to, from, next) {
		this.transitionOut(next)
	}
}
</script>

<style lang="stylus" scoped>
.Invite
	color #fff
</style>
